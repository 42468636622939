<template>
	<div>
		<a-spin :spinning="loading">
			<a-layout>
				<a-layout-sider class="layout-left-sider" style="background: #3D475D;overflow: hidden;z-index:10;flex: 0 0 80px; height: 100vh;box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.06);" width="80px">
					<div  class="logo">
						<img  src="../assets/image/logo48@2x.png" />
					</div>
					<div style="flex: 1 1 0%; overflow: hidden auto;">
						
						<div class="menu-list">
							
							<div class="menu-item" v-for="(item,index) in  menus" :key="index" @click="menuAct(index)" :class="{on:getSelectKey.indexOf(item.key) > -1}">
								<div class="text-center">
									<div class="iconfont ft24" :class="item.icon"></div>
									<!-- <img v-else style="width: 24px; height: 24px;" src="../assets/image/home_nav06@2x.png" /> -->
									<div class="mt8">{{item.name}}</div>
								</div>
							</div>
							<!-- <div class="menu-item"  >
								<div class="text-center">
									<div class="iconfont ft24" :class="'iconshop'"></div>
									<div class="mt8">收银台</div>
								</div>
							</div> -->
							
						</div>
					</div>
					<!-- <div class="bottom">
						<div class="notice-icon" @click="toNotice()">
							 <a-badge :count="5" dot>
								<i class="iconfont iconnotice ft20 cl-w" ></i>
							 </a-badge>
						</div>
						<div class="text-center mt20">
							<a-popover  placement="right">
								<template  slot="content">
									<div class="manage-action-popover">
										<div class="item">
											 <i class="iconfont iconshop ft14 mr10"></i>
											 <span class="ft14">切换店铺</span>
										</div>
										
										<div class="item">
											 <i class="iconfont iconquit ft14 mr10"></i>
											 <span class="ft14">退出管理</span>
										</div>
									</div>
								</template>
								<img class="manage-face" src="../assets/image/woyaoyou_head_defult@2x.png" />
							</a-popover>
						</div>
					</div> -->
				</a-layout-sider>
				<a-layout-content style="height: 100vh; background: #F7F8FA;">
					<div class="layout-header">
					
					</div>
					<div class="layout-header-cover">
						<a-row>
							<a-col :span="8">
								<div class="ft14 ftw400" style="text-align: left;">{{nowDate}}{{nowTime}}</div>
							</a-col>
							<a-col :span="8">
								<div class="ft14 ftw400 text-center">{{info.shop_name}}</div>
							</a-col>
							<a-col :span="8">
								<div class="ft14 ftw400 flex end" style="text-align: right;">
									<a-popover  placement="bottom">
										<template  slot="content">
											<div class="manage-action-popover">
												<div class="item" @click="loginOut()">
													 <i class="iconfont iconquit ft14 mr10"></i>
													 <span class="ft14">退出管理</span>
												</div>
											</div>
										</template>
										<div class="flex alcenter">
											<div>{{info.staff_name}}</div>
											<img class="manage-face ml5" src="../assets/image/woyaoyou_head_defult@2x.png" />
										</div>
									</a-popover>
								</div>
							</a-col>
						</a-row>
					</div>
					
					<div class="layout-content">
						<router-view></router-view>
					</div>
				</a-layout-content>
			</a-layout>
		</a-spin>
	</div>
</template>

<script>
	export default{
		
		data(){
			return {
				showCourse:false,
				nowDate: null, //存放年月日变量
				nowTime: null, //存放时分秒变量
				timer: "", //定义一个定时器的变量
				currentTime: new Date(), // 获取当前时间
				menus:[
					{name:'收银',icon:'iconhome_nav01',key:'cash',link:'/index'},
					{name:'挂单列表',icon:'icontabbar14',key:'gua',link:'/gua'},
					{name:'收银订单',icon:'iconmall_order',key:'order',link:'/order'},
					{name:'会员',icon:'iconhome_nav02',key:'member',link:'/member'},
					// {name:'预约',icon:'iconhome_nav04',key:'yuyue',link:'/yuyue'},
					
				],
				info:{
					shop_name:'',
					staff_name:'',
				},
			}
		},
		created(){
			this.timer = setInterval(this.getTime, 1000);
			this.loaddata();
		},
		methods:{
			loaddata(){
				if(this.loading==true) return;
				this.loading=true;
				this.$http.api('home').then(res=>{
					this.info=res.datas;
					this.loading=false;
				}).catch(res=>{
					this.loading=false;
				})
			},
			menuAct(index){
				let data = this.menus[index];
				if(data.key != this.getSelectKey){
					this.$router.push(data.link);
				}
			},
			
			loginOut(){
				localStorage.removeItem('cashier-beautiful-token');
				localStorage.removeItem('cashier-cart');
				localStorage.removeItem('cashier-cart-index');
				localStorage.removeItem('gua');
				localStorage.removeItem('cashier-member');
				this.$router.push('/');
			},
			
			//实时刷新时间
			getTime() {
				const date = new Date();
				const year = date.getFullYear();
				const month = date.getMonth() + 1;
				const day = date.getDate();
				const hour = date.getHours();
				const minute = date.getMinutes();
				const second = date.getSeconds();
				this.month = check(month);
				this.day = check(day);
				this.hour = check(hour);
				this.minute = check(minute);
				this.second = check(second);
			
				function check(i) {
					const num = (i < 10) ? ("0" + i) : i;
					return num;
				}
				this.nowDate = year + "年" + this.month + "月" + this.day + "日";
				this.nowTime = this.hour + ":" + this.minute + ":" + this.second;
			},
		},
		beforeDestroy() {
			if (this.timer) {
				clearInterval(this.timer); // 在Vue实例销毁前，清除定时器
			}
		},
		
	}
</script>

<style>
	.layout-left-sider .ant-layout-sider-children{
		display: flex;
		flex-direction: column;
		height: 100%;
		line-height: 1;
		
	}
	.layout-left-sider .ant-layout-sider-children ::-webkit-scrollbar{
		width: 6px;
		height: 6px;
	}
	.layout-left-sider  .ant-layout-sider-children ::-webkit-scrollbar-thumb {
		background: rgb(0 0 0 / 15%);;
		border-radius: 3px;
		-webkit-box-shadow: inset 0 0 5px  rgb(0 0 0 / 15%);
	}
	.layout-left-sider .ant-layout-sider-children ::-webkit-scrollbar-track {
	    background: rgb(255 255 255 / 15%);
	    border-radius: 3px;
	    -webkit-box-shadow: inset 0 0 5px rgb(255 255 255 / 15%);
	}
	
	.layout-left-sider .logo{
		padding: 24px 16px;
		position: relative;
		display: flex;
		align-items: center;
	}
	.layout-left-sider .logo img{
		width: 48px;
		height: 48px;
	}
	.layout-left-sider .bottom{
		width: 100%;
		position: relative;
		padding: 24px 20px;
		border-top: 1px solid rgba(255,255,255,0.1);
	}
	.layout-left-sider .menu-list{
		padding: 0 6px;
		background: #3D475D;
	}

	.layout-left-sider .menu-item{
		width: 68px;
		height: 74px;
		background: #3D475D;
		border-radius: 8px;
		color: #9198A9;
		transition: all 0.3s;
		display: flex;
		justify-content: center;
		align-items: center;
		cursor: pointer;
	}
	.layout-left-sider .menu-item.on{
		background: #4772FF;
		color:#FFFFFF;
	}
	.layout-left-sider .notice-icon{
		text-align: center;
		cursor: pointer;
	}
	 .manage-face{
		width: 40px;
		height: 40px;
		box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.06);
		border: 1px solid #FFFFFF;
		border-radius: 20px;
		cursor: pointer;
		background: #FFFFFF;
	}
	.add-action-popover{
		padding: 12px 16px;
	}
	.add-action-popover .item{
		width: 160px;
		height: 48px;
		border-radius: 4px;
		padding: 16px;
		display: flex;
		align-items: center;
		color:#656A75;
		cursor: pointer;
		transition: background-color 0.3s;
	}
	.add-action-popover .item:hover{
		color: #567AF0;
		background: rgba(86, 122, 240, 0.06);
	}
	
	.manage-action-popover{
		padding: 12px 16px;
	}
	.manage-action-popover .item{
		width: 100px;
		height: 30px;
		border-radius: 4px;
		padding: 0 10px;
		display: flex;
		align-items: center;
		color:#656A75;
		cursor: pointer;
		transition: background-color 0.3s;
	}
	.manage-action-popover .item:hover{
		color: #567AF0;
		background: rgba(86, 122, 240, 0.06);
	}
	
	@media screen and (max-width: 1024px) {
		.layout-header {
			height: 60px;
			width: calc(100% - 80px);
		}
	
		.layout-header-cover {
			height: 60px;
			background: #3D475D;
			padding: 0 20px 0 20px;
			line-height: 60px;
			color: #ffffff;
			position: fixed;
			top: 0;
			z-index: 9;
			width: calc(100% - 80px);
		}
	
		.layout-content {
			min-height: calc(100vh-60px);
		}
	
	}
	
	@media screen and (min-width:1025px) and (max-width: 1920px) {
		.layout-header {
			height: 60px;
			width: calc(100% - 80px);
		}
	
		.layout-header-cover {
			height: 60px;
			background: #3D475D;
			padding: 0 20px 0 20px;
			line-height: 60px;
			color: #ffffff;
			position: fixed;
			top: 0;
			z-index: 9;
			width: calc(100% - 80px);
		}
	
		.layout-content {
			min-height: calc(100vh - 60px);
		}
	}
</style>